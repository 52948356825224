<template>
  <div>
    <v-dialog v-model="show" @click:outside="closeDialog">

      <v-card>
        <v-card-title>
          <v-progress-linear indeterminate color="teal" v-if="loading"></v-progress-linear>

          <strong>{{ $t('views.dialogOrderDetail.title') }} |</strong>&nbsp;#{{ ddpayOrderId }}
        </v-card-title>

        <v-card-text>
          <v-row>
             <!-- BASIC DATA ITERATOR FOR DDPW ORDER INFO -->
             <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.ddpayOrderId') }}</td>
                      <td>{{ ddpayOrder.ddpayOrderId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.merchantOrderId') }}</td>
                      <td>{{ ddpayOrder.orderId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.amount') }}</td>
                      <td>{{ ddpayOrder.currency }}${{ Number(ddpayOrder.amount || 0).toLocaleString() }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paymentMode') }}</td>
                      <td>
                        {{ 
                          {
                            'sd': "一般線上",
                            'cd': "客金",
                            'md': "主掃支付",
                            'cpm': "被掃支付",
                            'ba': "自助／其它",
                            'ttp': "靠卡感應"
                          }[ ddpayOrder.paymentMode ]
                        }}
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.status') }}</td>
                      <td>{{ ddpayOrder.status }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.createdAt') }}</td>
                      <td>{{ convertTsToDateTime(ddpayOrder.createdAt) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <!-- MORE DATA ITERATOR FOR DDPW ORDER INFO -->
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.vendor') }}</td>
                      <td>
                        <span>{{ ddpayOrder.vendor }}</span>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paymentFlow') }}</td>
                      <td>
                        <div v-if="getPaymentServiceIcon(ddpayOrder.paymentType)">
                          <img :src="getPaymentServiceIcon(ddpayOrder.paymentType)" style="height: 28px;">
                        </div>
                        <div v-else>{{ ddpayOrder.paymentType }}</div>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.points') }}</td>
                      <td>
                        <span>{{ ddpayOrder.pointAmount? 'Yes': 'No' }}</span>
                        <span v-if="ddpayOrder.pointName"> {{ $t('views.dialogOrderDetail.pointName') }}：{{ ddpayOrder.pointName }} </span>
                        <span v-if="ddpayOrder.pointAmount"> {{ $t('views.dialogOrderDetail.pointAmount') }}：{{ ddpayOrder.pointAmount }} </span>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.tickets') }}</td>
                      <td>{{ ddpayOrder.tickets && ddpayOrder.tickets.length? 'Yes': 'No' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col cols="12">
              <h4 class="text-center">{{ $t('views.dialogOrderDetail.paymentTransaction') }}</h4>
            </v-col>
            <!-- BASIC DATA ITERATOR FOR PAYMENT ORDER INFO -->
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.ddpgOrderId') }}</td>
                      <td> {{ order.ddpgOrderId || order.paymentOrderId}}</td>
                    </tr>
                    <!-- <tr v-if="order.organization_name"><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.organization') }}</td>
                      <td>{{ order.organization_name }}</td>
                    </tr> -->
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.merchant') }}</td>
                      <td>{{ order.shopName }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.merchantOrderId') }} </td>
                      <td>{{ order.merchantOrderId }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.amount') }}</td>
                      <td>{{ order.currency }}${{ Number(order.amount || 0 ).toLocaleString() }}</td>
                    </tr>
                    <!-- <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paidVia') }}</td>
                      <td>{{ order.payment_type }}</td>
                    </tr> -->
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.status') }}</td>
                      <td>{{ order.status }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.createdAt') }}</td>
                      <td>{{ convertTsToDateTime(order.createdAt) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <!-- MORE DATA ITERATOR FOR PAYMENT ORDER INFO -->
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paymentService') }}</td>
                      <td>
                        <div v-if="getPaymentServiceIcon(order.paymentService)">
                          <img :src="getPaymentServiceIcon(order.paymentService)" style="height: 28px;">
                        </div>
                        <div v-else>{{ order.paymentService }}</div>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paymentType') }}</td>
                      <td>
                        <div v-if="getPaymentServiceIcon(order.paymentType)">
                          <img :src="getPaymentServiceIcon(order.paymentType)" style="height: 28px;">
                        </div>
                        <div v-else>{{ order.paymentType }}</div>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.acquirer') }}</td>
                      <td v-if="order.acquirer">［{{ order.acquirer.id }} ］{{ order.acquirer.name }}</td>
                      <td v-else></td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.cardNo') }}</td>
                      <td v-if="order.ctx">{{ order.ctx.cardNo }}</td>
                      <td v-else-if="order.paymentContext">{{ order.paymentContext.cardNo }}</td>
                      <td v-else></td>
                    </tr>
                    <!-- <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.paymentServiceName') }}</td>
                      <td>{{ order.payment_service_name }}</td>
                    </tr> -->
                    <!-- <tr><td class="font-weight-medium">{{ $t('views.dialogOrderDetail.useTds') }}</td>
                      <td>{{ order.use_tds == 1? 'Yes': 'No' }}</td>
                    </tr> -->
                    <tr v-if="user.level === 'superadmin'"><td class="font-weight-medium">Request Context (for debug)</td>
                      <td>
                        <a @click="showRequestContext = true" v-if="!showRequestContext">Show</a>
                        <span v-if="showRequestContext">{{ order.ctx || order.paymentContext }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <!-- LIST OF TRANSACTIONAL HISTORY -->
            <v-col cols="12">
              <h4 class="text-center">{{ $t('views.dialogOrderDetail.transactionalHistory') }}</h4>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="col in transactionSchema" :key="col.value">
                        {{ col.text }}
                      </th>
                      <th>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="t in transactions" :key="t['id']">
                      <td v-for="col in transactionSchema" :key="col.value">
                        <span v-if="col.value==='createdAt'">{{ convertTsToDateTime(t[col.value]) }}</span>
                        <span v-else> {{ t[col.value] }}</span>
                      </td>
                      <td width="20%">
                        <v-btn fab x-small color="info" @click="showTransactionDetail(t)">
                          <v-icon>mdi-information</v-icon>
                        </v-btn>
                        <!-- <v-btn v-if="['paid', 'captured'].indexOf(t['status']) >= 0"
                          rounded small color="error" class="ml-2" @click="requestRefund(t)">
                          <v-icon left>mdi-cash-refund</v-icon>
                          {{ $t('views.dialogOrderDetail.refund') }}
                        </v-btn> -->
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <!-- EXPANSION - TRANSACTION CONTEXT -->
              <v-expand-transition>
                <v-card outlined v-show="expansion.detail.show" class="ma-2 pa-2">
                  <v-card-text>
                    {{ JSON.stringify(expansion.detail.context, null, 2) }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text @click="expansion.detail.show = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-expand-transition>
            </v-col>

          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GetIconMixin } from '@/mixins/get-icons';
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'DdpayOrderDetailDialog',
  props: {
    show: Boolean,
    // paymentOrderId: String,
    ddpayOrderId: String,
    paymentMode: String
  },
  mixins: [
    GetIconMixin
  ],

  mounted() {

  },

  methods: {
    closeDialog() {
      this.expansion.detail.show = false;
      this.$emit('update:show', false);
    },
    convertTsToDateTime(ts) {
      return moment.unix(ts).format('YYYY/M/D h:mm:ssA')
    },
    fetchDdpayOrderInfo() {
      // this.showRequestContext = false;
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ddpay-orders/${this.ddpayOrderId}`
      )
      .then(response => {
        this.ddpayOrder = response.data;
      })
      .catch(err => {
        console.error(`Failed to fetch ddpay order information.`, err.response);
        this.$notify({
          text: `Failed to fetch ddpay order information.`,
          type: 'error', 
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchPaymentOrderInfo() {
      // ba 沒有金流，不呼叫取得金流訂單的API; API處理了，前端不動
      // if (this.paymentMode === 'ba') {
      //   this.order = {};
      //   return;
      // }

      this.showRequestContext = false;
      this.loading = true;
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ddpay-orders/${this.ddpayOrderId}/payment`
        + `?payment-mode=${this.paymentMode}`
      )
      .then(response => {
        this.order = response.data;
      })
      .catch(err => {
        console.error(`Failed to fetch payment order information.`, err.response);
        this.order = {};
        this.$notify({
          text: `Failed to fetch payment order information.`,
          type: 'error', 
        });
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchTransactionHistory() {
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/ddpay-orders/${this.ddpayOrderId}/history`
      )
      .then(response => {
        this.transactions = response.data;
      })
      .catch(err => {
        console.error(`Failed to fetch transactional history.`, err.response);
        this.transactions = [];
        this.$notify({
          text: `Failed to fetch transactional history.`,
          type: 'error', 
        });
      });
      
    },

    showTransactionDetail(_transaction) {
      this.expansion.detail.context = _transaction['context'];
      this.expansion.detail.show = true;
    },

    // requestRefund() {
    //   if (!confirm(`Sure you want to refund for $${this.order['amount']}? This cannot be undone.`))
    //     return;

    //   axios.delete(
    //     `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orders/${this.paymentOrderId}/refund`,
    //     {
    //       data: { "userId": this.user['id'] }//Who request this refund?
    //     }
    //   )
    //   .then(response => {
    //     this.$notify({
    //       text: `Refund has been requested (order ${this.paymentOrderId}). It will be processed in a while.`,
    //       type: 'warn'
    //     });
    //     //FIXME: Refresh the transactional history after 3 seconds.
    //     setTimeout(() => {
    //       this.fetchTransactionHistory();
    //     }, 3000);
    //   })
    //   .catch(err => {
    //     this.$notify({
    //       text: `Cannot request for refund (order ${this.paymentOrderId}). `
    //         + `The banking system may be still processing the transaction, please try again some time later.`,
    //       type: 'error', duration: 10000
    //     });
    //   });
    // },
  },

  data() {
    return {
      loading: false,

      ddpayOrder: { },
      order: { },
      transactions: [ ],

      showRequestContext: false,

      expansion: {
        detail: {
          show: false,
          context: { }
        }
      }
    }
  },

  computed: {
    transactionSchema() {
      return [
        { value: 'recTradeId', text: this.$t('views.dialogOrderDetail.transactionId') },
        { value: 'action', text: this.$t('views.dialogOrderDetail.action') },
        { value: 'amount', text: '$' },
        { value: 'status', text: this.$t('views.dialogOrderDetail.status') },
        { value: 'code', text: this.$t('views.dialogOrderDetail.code') },
        { value: 'message', text: this.$t('views.dialogOrderDetail.message') },
        { value: 'createdAt', text: this.$t('views.dialogOrderDetail.time') }
      ];
    },

    user() {
      return this.$store.getters.user;
    }
  },

  watch: {
    'ddpayOrderId': {
      immediate: false,
      handler(id) {
        if (id && id.length) {
          this.fetchDdpayOrderInfo();
          this.fetchPaymentOrderInfo();
          this.fetchTransactionHistory();
        }
      }
    }
  }
}
</script>